import React from "react";
import "./LeisureTime.scss";
import TitleWithDescription from "../UI/TitleWithDescription/TitleWithDescription";
import Container from "../UI/Container/Container";
import {useTranslation} from "react-i18next/hooks";


const LeisureTime = ({leisure_time}) => {
  const [t] = useTranslation();

  return (
    <div className="leisure-time">
      <Container>
        <TitleWithDescription
          title={t('Дозвілля')}
          description={''}
          center
        />
        <div className="leisure-time-list">
          {
            leisure_time.map((item) => {
              return (
                <a href={item.url} className="leisure-time-item" target="_blank">
                  <img src={item.images && item.images.length ? item.images[0].img : ''} className="leisure-time-item__img"/>
                  {item.title &&
                    <h5 className="leisure-time-item__title" dangerouslySetInnerHTML={{__html: item.title}}></h5>}
                  {item.descr &&
                    <h6 className="leisure-time-item__desc" dangerouslySetInnerHTML={{__html: item.descr}}></h6>}
                </a>
              )
            })
          }
        </div>
      </Container>
    </div>
  )
}

export default LeisureTime;
