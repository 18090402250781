import React from "react";
import LinkPrefix from "../../../UI/LinkPrefix/LinkPrefix.jsx";
import "./Buttons.scss";



export const Button = (props) => {
    return (
        <div className={`button ${props.type === 'outline' ? 'button-outline' : ''}`} >
            <button {...props}>{props.children}</button>
        </div>
    )
};

export const ButtonLink = (props) => {
    return (
        <div className={`button ${props.type === 'outline' ? 'button-outline' : ''}`} >
            <LinkPrefix {...props}>
                {props.children}
            </LinkPrefix>
        </div>
        )

}