import React, {Component} from "react";
import "./Location.scss";
import TitleWithDescription from "../UI/TitleWithDescription/TitleWithDescription";

class Location extends Component {

  render() {
    const {location = {}, name} = this.props;

    return (
      <div className="location" name={name}>
        <div className="location__left">
          <TitleWithDescription
            title={location.title}
            description={location.descr}
          />
        </div>

          <div className="location__right">
            <a
              href="https://www.google.com.ua/maps/place/%D0%A0%D0%B5%D1%86%D0%B5%D0%BF%D1%86%D1%96%D1%8F+%D0%B3%D0%BE%D1%82%D0%B5%D0%BB%D1%8E+%22%D0%91%D1%83%D0%BA%D0%BE%D0%B2%D0%B5%D0%BB%D1%8C%22/@48.3535828,24.4124172,18.25z/data=!4m6!3m5!1s0x47371864bd85dbcd:0x63d36d9cc116e129!8m2!3d48.3538268!4d24.4127771!16s%2Fg%2F1q2w7j2jj?entry=ttu"
              target="_blank">
              <div className="location__img"
                   style={{backgroundImage: 'url("/images/background/location.jpg")'}}></div>
            </a>
          </div>
      </div>
    )
  }
}


export default Location;