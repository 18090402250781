import React, {Component} from "react";
import Reservation from "../../components/Reservation/Reservation.jsx";
import AboutHotel from "../../components/AboutHotel/AboutHotel.jsx";
import Rooms from "../../components/Rooms/Rooms.jsx";
import Services from "../../components/Services/Services.jsx";
import Prices from "../../components/Prices/Prices.jsx";
import Location from "../../components/Location/Location.jsx";
import {withRouter} from "react-router-dom";
import "./Body.scss";
import {actionFetchData} from "../../store/actions/actions";
import Header from "../Header/Header.jsx";
import New from "../../components/News/New.jsx";
//import "../../task";
import {connect} from "react-redux";
import {initLng, refetchPageData} from "../../helpers/network";
import Aux from "../../components/hoc/AuxC/AuxC";
import LeisureTime from "../../components/LeisureTime/LeisureTime";

//  переробити детально для румів
// Забрати в Про готель зайвий функціонал
// Добавити тайтл про готелі
// поговорити про ціни


class Body extends Component {


    componentWillMount() {
        const {params = {}} = this.props.match;
        initLng(params.lng);


        this.props.fetchData();
    }

    componentWillReceiveProps(nextProps) {
        refetchPageData(nextProps, this.props, () => {
            this.props.fetchData();
        })
    }


    render() {
        const {isFetching, data = {}} = this.props;
        const {rooms = [], add_blocks=[], shown_prices, prices = [], sliders = [], location = {}, about_us,price_title={}, room_title = {}, leisure_time = []} = data;

        return (
            <Aux>
                <Header sliders={sliders} shown_prices={shown_prices} add_blocks={add_blocks}/>
                <div className="body">
                    <div className="body__wrapper">
                        <div style={{background: '#F6F6F6'}}>
                            <Reservation name="reservation"/>
                        </div>

                        <AboutHotel name="about" isFetching={isFetching} about_us={about_us}/>
                        <Rooms name="rooms" isFetching={isFetching} rooms={rooms} room_title={room_title}/>

                        {
                            add_blocks && add_blocks.length ?
                              <>
                                  {
                                      add_blocks.map((item) => {
                                          return   (
                                            <AboutHotel name={item.title} isFetching={isFetching} about_us={[item]}/>
                                          )
                                      })}
                              </>
                              : null
                        }

                        {/*<Services name="services" isFetching={isFetching} services={services} service_title={service_title}/>*/}

                        <div name="leisure">{leisure_time && leisure_time.length ? <LeisureTime  leisure_time={leisure_time}/> : null}</div>
                        {shown_prices ? <Prices name="prices" isFetching={isFetching} prices={prices} price_title={price_title}/> : null}
                        <Location name="location" isFetching={isFetching} location={location}/>
                    </div>

                </div>
                {data.new && data.new.descr && <New news={data.new}/>}
            </Aux>
        )
    }
}


const mapStateToProps = (state) => {
    const {data = {}, isFetching} = state.main;


    return {
        data,
        isFetching
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchData: () => {
            dispatch(actionFetchData())
        }
    }
};


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Body));