import React, {Component} from "react";
import Container from "../UI/Container/Container";
import "./AboutHotel.scss";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import TitleWithDescription from "../UI/TitleWithDescription/TitleWithDescription";


class AboutHotel extends Component {
  state = {
    index: 0,
  };


  render() {
    const {about_us = [], name, title = ""} = this.props;


    const settings = {
      dots: true,
      arrows: false,
      infinite: true,
      speed: 500,
      initialSlide: this.state.index,
      slidesToShow: 1,
      slidesToScroll: 1,
      afterChange: (index) => {
        this.setState({index})
      },
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: true,
            dots: true,
            draggable: true
          }
        }]
    };

    const settings2 = {
      dots: false,
      // arrows: false,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
    };

    return (

      <div className="about" name={name}>
        <div className="about__wrapper">
          <div className="about__left">
            <TitleWithDescription
              title={about_us.length && about_us[this.state.index] ? about_us[this.state.index].title : ""}
              description={about_us.length && about_us[this.state.index] ? about_us[this.state.index].descr : ""}
            />
          </div>
          <div className="about__right">
            {about_us.length ? <div className="about__carousel__wrap">
              <Slider {...settings} className={"about__carousel"}>
                {
                  about_us.map((item, index) => {
                    return (
                      <div key={index}>
                        <Slider {...settings2} className={"about__carousel"}>
                          {
                            item.images.map((image, index) => {
                              return (
                                <div key={index}>
                                  <div className="about__image"
                                       style={{backgroundImage: `url('${image.img}')`}}></div>
                                </div>
                              )
                            })
                          }
                        </Slider>
                      </div>
                    )
                  })
                }
              </Slider>
            </div> : null}
          </div>
        </div>
      </div>

    )
  }
}

export default AboutHotel;