import React from "react";
import "./Footer.scss";
import {
  IconFacebook,
  IconInstagram,
  IconTripAdvisor,
  IconBooking,
} from "../../components/UI/Icons/Icons";
import * as moment from "moment";
import {withI18n} from "react-i18next";
// import IconFaceBook from "./icons/IconFaceBook";
// import IconFaceBook from "./icons/IconFaceBook";


const Footer = (props) => {
  const {t} = props;
  return (
    <footer className="footer" name="contacts">
      <div className="footer__wrapper">

        <div className="footer__column">
          <div className="footer__blocks">
            <div className="footer__left">

              <div className="footer__block">
                <div>
                  <div className="footer__title">{t('Бронювання готелю')}</div>
                  <a href="tel:+380342591100" className="footer__phone">+380 342 591 100</a>
                </div>
                <div className="footer__email">
                  E-mail: <a href= "mailto: web@bukovel.com">web@bukovel.com</a>
                </div>
              </div>


              <div className="footer__block">
                <div>
                  <div className="footer__title">{t('Бронювання для корпоративних груп')}</div>
                  <a href="tel: +380671043989" className="footer__phone">+38 067 104 39 89</a>
                  <a href="tel: +380342591100" className="footer__phone">+38 (0342) 59 11 00</a>
                </div>

                <div className="footer__email">
                  E-mail: <a  href="mailto: sales_hotel@bukovel.com">sales_hotel@bukovel.com</a>
                </div>
              </div>


              <span className="footer__address">{t('адрес')}</span>

              <div className="footer__icons">
                <a target="_blank" className="footer__social" href="https://www.facebook.com/people/Bukovel-Hotel-Bukovel-Apart/61560453534710/"><IconFacebook/></a>
                <a target="_blank" className="footer__social" href="https://www.instagram.com/bukovel_hotel_apart/?igsh=MXZxbTdxdTg1dWt0MQ%3D%3D"><IconInstagram/> </a>
                <a target="_blank" className="footer__social" href="https://www.tripadvisor.com/Hotel_Review-g3426922-d1421276-Reviews-Bukovel_Hotel-Polyanytsya_Bukovel_Ivano_Frankivsk_Oblast.html"><IconTripAdvisor/> </a>
              </div>
            </div>
          </div>

          <div className="footer__bottom">
            <div className="footer__info">
              © {moment().format('YYYY')} {t('Офіційний сайт')} "Bukovel Hotel"
            </div>

          </div>
        </div>


      </div>
      <div className="footer__background" style={{backgroundImage: `url('/images/background/bukovel.jpg')`}}></div>
      <div className="footer__shadow left"></div>
      <div className="footer__shadow right"></div>
      <div className="footer__shadow top"></div>
    </footer>
  )

};

export default withI18n()(Footer);